<template>
  <div class="star-options" v-if="$store.state.showSelectedInfoButtons && selectedObject && showSelectionWindow" style="position: absolute; right: 10px; bottom: -60px;">
    <v-btn class="star-btn" v-if="showPointToButton && typeof selectedObject.registry !== 'undefined' && (!webShareApiSupported && !appMobile)" fab small color="transparent" @click.native="showShareLinkDialog = !showShareLinkDialog">
      <img src="@/assets/images/svg/ui/ic_share.svg" height="38px" style="min-height: 38px"></img>
    </v-btn>
    <v-btn class="star-btn" v-if="showPointToButton && typeof selectedObject.registry !== 'undefined' && (appMobile && appVersion)" fab small color="transparent" @click.native="saveStarsMobile">
      <img src="@/assets/images/svg/ui/save.svg" style="margin-top: 0"></img>
    </v-btn>
    <v-btn class="star-btn" v-if="showPointToButton && typeof selectedObject.registry !== 'undefined' && (webShareApiSupported || appMobile)" fab small color="transparent" @click.native="shareViaWebShare">
      <img src="@/assets/images/svg/ui/ic_share.svg" height="38px" style="min-height: 38px;margin-top: 0;"></img>
    </v-btn>
    <v-btn class="star-btn" v-if="showPointToButton" fab small color="transparent" @mousedown="zoomOutButtonClicked()" style="margin:14px 8px 10px 8px;">
      <img :class="{bt_disabled: !zoomOutButtonEnabled, mobileZoomTo: appMobile}" src="@/assets/images/svg/ui/remove_circle_outline.svg" height="38px" style="min-height: 38px"></img>
    </v-btn>
    <v-btn class="star-btn" :class="{zoomDisabled: fovFunction}" v-if="showPointToButton" fab small color="transparent" @mousedown="zoomInButtonClicked()">
      <img :class="{bt_disabled: !zoomInButtonEnabled, mobileZoomOut: appMobile}" src="@/assets/images/svg/ui/add_circle_outline.svg" height="38px" style="min-height: 38px"></img>
    </v-btn>
    <v-btn class="star-btn" v-if="showPointToButton && (!webShareApiSupported && !appMobile)" fab small color="transparent" v-on:click.native="lockToSelection()" style="margin-right: 0">
      <img src="@/assets/images/svg/ui/point_to.svg" height="38px" style="min-height: 38px"></img>
    </v-btn>
  </div>
</template>
<script>

import Moment from 'moment'
import swh from '@/assets/sw_helpers.js'
// import selectedButtons from '@/components/selected-object-buttons.vue'

export default {
  // components: { selectedButtons },
  data: function () {
    return {
      showMinorNames: false,
      wikipediaData: undefined,
      shareLink: undefined,
      showShareLinkDialog: false,
      copied: false,
      checkApp: false,
      checkAppVersion: false,
      showContent: 'Show More',
      zoomValue: 11,
      zoomMobileValue: 15,
      fov: false,
      windowWidth: window.innerWidth,
      items: []
    }
  },
  computed: {
    appMobile: function () {
      var app = swh.checkApp()
      if (app) {
        return true
      } else {
        return false
      }
    },
    appVersion: function () {
      var version = swh.checkAppVersion()
      if (version) {
        return true
      } else {
        return false
      }
    },
    webShareApiSupported: function () {
      if (navigator.share) {
        return navigator.share
      } else {
        return false
      }
    },
    // Unset comment for prod
    showSelectionWindow: function () {
      return this.$store.state.stel && this.$store.state.stel.show_selection_window
    },
    selectedObject: function () {
      return this.$store.state.selectedObject
    },
    stelSelectionId: function () {
      return this.$store.state.stel && this.$store.state.stel.selection ? this.$store.state.stel.selection : undefined
    },
    title: function () {
      return this.selectedObject ? this.otherNames[0] : 'Selection'
    },
    otherNames: function () {
      if ('registry' in this.selectedObject) {
        return [this.selectedObject.registry.name]
      }

      return this.selectedObject ? swh.namesForSkySource(this.selectedObject, 26) : undefined
    },
    otherNames1to7: function () {
      return this.otherNames.slice(1, 8)
    },
    otherNames8andMore: function () {
      return this.showMinorNames ? this.otherNames.slice(8) : []
    },
    wikipediaSummary: function () {
      if (!this.wikipediaData) return ''
      const page = this.wikipediaData.query.pages[Object.keys(this.wikipediaData.query.pages)[0]]
      if (!page || !page.extract) return ''
      const wl = '<b><a style="color: #62d1df;" target="_blank" rel="noopener" href="' + this.wikipediaLink + '">wikipedia</a></b></i>'
      return page.extract.replace(/<p>/g, '').replace(/<\/p>/g, '') + '<span class="grey--text text-caption" style="margin-left:auto; margin-right:0;"><i>&nbsp; ' + this.$t('more on {0}', [wl]) + '</span>'
    },
    wikipediaLink: function () {
      const page = this.wikipediaData.query.pages[Object.keys(this.wikipediaData.query.pages)[0]]
      if (!page || !page.extract) return ''
      return 'https://en.wikipedia.org/wiki/' + page.title
    },
    type: function () {
      if (!this.selectedObject) return this.$t('Unknown')
      let morpho = ''
      if (this.selectedObject.model_data && this.selectedObject.model_data.morpho) {
        morpho = swh.nameForGalaxyMorpho(this.selectedObject.model_data.morpho)
        if (morpho) {
          morpho = morpho + ' '
        }
      }
      return morpho + swh.nameForSkySourceType(this.selectedObject.types[0])
    },
    icon: function () {
      return swh.iconForSkySource(this.selectedObject)
    },
    showPointToButton: function () {
      if (!this.$store.state.stel.lock) return true
      if (this.$store.state.stel.lock === this.$store.state.stel.selection ||
        this.$store.state.stel.lock !== this.$store.state.stel.selection) return true
      return false
    },
    zoomInButtonEnabled: function () {
      if (!this.$store.state.stel.lock || !this.selectedObject) return false
      return true
    },
    fovFunction: function () {
      if (this.$store.state.stel.fov * 180 / Math.PI > 6) return false
      return true
    },
    zoomOutButtonEnabled: function () {
      if (!this.$store.state.stel.lock || !this.selectedObject) return false
      return true
    },
    extraButtons: function () {
      return swh.selectedObjectExtraButtons
    },
    pluginsSelectedInfoExtraGuiComponents: function () {
      let res = []
      for (const i in this.$stellariumWebPlugins()) {
        const plugin = this.$stellariumWebPlugins()[i]
        if (plugin.selectedInfoExtraGuiComponents) {
          res = res.concat(plugin.selectedInfoExtraGuiComponents)
        }
      }
      return res
    },
    showContentLabel: function () {
      return this.showContent ? this.$t('Show More') : this.$t('Show Less')
    }
  },
  watch: {
    selectedObject: function (s, oldObject) {
      this.showMinorNames = false
      this.wikipediaData = undefined
      if (!s) {
        if (this.timer) clearInterval(this.timer)
        this.timer = undefined
        return
      }
      var that = this
      const isSameStar = s && s.registry && oldObject && oldObject.registry && s.registry.registration_number === oldObject.registry.registration_number
      that.items = that.computeItems(isSameStar)
      if (that.timer) clearInterval(that.timer)
      // that.timer = setInterval(() => { that.items = that.computeItems() }, 1000)

      /*
      swh.getSkySourceSummaryFromWikipedia(s).then(data => {
        that.wikipediaData = data
      }, reason => { })
      */
    },
    stelSelectionId: function (s) {
      if (!this.$stel.core.selection) {
        this.$store.commit('setSelectedObject', 0)
        return
      }
      swh.sweObj2SkySource(this.$stel.core.selection, this.$store).then(res => {
        this.$store.commit('setSelectedObject', res)
      }, err => {
        console.log("Couldn't find info for object " + s + ':' + err)
        this.$store.commit('setSelectedObject', 0)
      })
    },
    showShareLinkDialog: function (b) {
      this.shareLink = swh.getShareLink(this)
    }
  },
  methods: {
    onResizeWidth: function () {
      this.windowWidth = window.innerWidth
      return this.windowWidth
    },
    saveStarsMobile: async function () {
      if (this.appVersion) {
        try {
          var registryNumber = this.selectedObject.registry.registration_number
          // Change to live API url
          // const apiData = await fetch('https://stellarium-api.stgin.com/api/v1/skysources/?q=' + registryNumber)
          const apiData = await fetch(process.env.VUE_APP_NOCTUASKY_API_SERVER + '/api/v1/skysources/?q=' + registryNumber)
          if (!apiData.ok) {
            throw new Error('Error with API response')
          }
          const data = await apiData.json()
          window.MessageInvoker.postMessage(JSON.stringify({
            type: 'save',
            data: {
              interest: 1,
              match: data[0].match,
              model_data: {
                Bmag: data[0].model_data.Bmag,
                Imag: data[0].model_data.Imag,
                Rmag: data[0].model_data.Rmag,
                Umag: data[0].model_data.Umag,
                Vmag: data[0].model_data.Vmag,
                ra: data[0].model_data.ra,
                de: data[0].model_data.de,
                pm_ra: data[0].model_data.pm_ra,
                pm_de: data[0].model_data.pm_de,
                plx: data[0].model_data.plx,
                rv: data[0].model_data.rv,
                spect_t: data[0].model_data.spect_t
              },
              model: data[0].model,
              components: data[0].components[0],
              registry: {
                registration_number: registryNumber,
                name: data[0].registry.name,
                registered_at: data[0].registry.registered_at,
                lat: data[0].registry.lat,
                lon: data[0].registry.lon,
                distance: this.computeItems()[2].value,
                ra: this.computeItems()[3].value,
                dec: this.computeItems()[4].value,
                az: this.computeItems()[5].value,
                alt: this.computeItems()[6].value
              },
              types: ['*']
            }
          }))
        } catch (e) {
          console.log(e)
        }
      }
    },
    shareViaWebShare: function () {
      navigator.share({
        title: 'Star Finder Star',
        text: 'Look at this Beautiful Star!',
        url: 'https://star-finder.starregistration.net/' + '?q=' + this.selectedObject.registry.registration_number
      })
    },
    computeItems: function (isSameStar = false) {
      const obj = this.$stel.core.selection
      if (!obj) return []
      const that = this

      const ret = []

      const addAttr = (key, attr, format) => {
        const v = obj.getInfo(attr)
        if (v && !isNaN(v)) {
          ret.push({
            key: key,
            value: format ? format(v) : v.toString()
          })
        }
      }

      if ('registry' in this.selectedObject) {
        const registry = this.selectedObject.registry
        ret.push({
          key: that.$t('Registration Number'),
          value: registry.registration_number
        })

        if (!isSameStar) {
          this.zoomPicker()
        }
      }

      addAttr(that.$t('Magnitude'), 'vmag', this.formatMagnitude)// magnitude for zoom
      addAttr(that.$t('Distance'), 'distance', this.formatDistance)
      const formatInt = function (num, padLen) {
        const pad = new Array(1 + padLen).join('0')
        return (pad + num).slice(-pad.length)
      }
      const formatRA = function (a) {
        const raf = that.getFormatted('lon', that.$stel.a2tf(a, 1))
        if (that.appVersion && that.appMobile) {
          return formatInt(raf.hours, 2) + 'h' + ' ' + formatInt(raf.minutes, 2) + 'm' + ' ' + formatInt(raf.seconds, 2) + '.' + raf.fraction + 's'
        } else {
          return '<div class="radecVal">' + formatInt(raf.hours, 2) + '<span class="radecUnit">h</span>&nbsp;</div><div class="radecVal">' + formatInt(raf.minutes, 2) + '<span class="radecUnit">m</span></div><div class="radecVal">' + formatInt(raf.seconds, 2) + '.' + raf.fraction + '<span class="radecUnit">s</span></div>'
        }
      }
      const formatAz = function (a) {
        const raf = that.$stel.a2af(a, 1)
        if (that.appVersion && that.appMobile) {
          return formatInt(raf.degrees < 0 ? raf.degrees + 180 : raf.degrees, 3) + '°' + ' ' + formatInt(raf.arcminutes, 2) + '\'' + ' ' + formatInt(raf.arcseconds, 2) + '.' + raf.fraction + '"'
        } else {
          return '<div class="radecVal">' + formatInt(raf.degrees < 0 ? raf.degrees + 180 : raf.degrees, 3) + '<span class="radecUnit">°</span></div><div class="radecVal">' + formatInt(raf.arcminutes, 2) + '<span class="radecUnit">\'</span></div><div class="radecVal">' + formatInt(raf.arcseconds, 2) + '.' + raf.fraction + '<span class="radecUnit">"</span></div>'
        }
      }
      const formatDec = function (a, useFormatted = true) {
        const raf = useFormatted ? that.getFormatted('lat', that.$stel.a2af(a, 1)) : that.$stel.a2af(a, 1)
        if (that.appVersion && that.appMobile) {
          return raf.sign + formatInt(raf.degrees, 2) + '°' + ' ' + formatInt(raf.arcminutes, 2) + '\'' + ' ' + formatInt(raf.arcseconds, 2) + '.' + raf.fraction + '"'
        } else {
          return '<div class="radecVal">' + raf.sign + formatInt(raf.degrees, 2) + '<span class="radecUnit">°</span></div><div class="radecVal">' + formatInt(raf.arcminutes, 2) + '<span class="radecUnit">\'</span></div><div class="radecVal">' + formatInt(raf.arcseconds, 2) + '.' + raf.fraction + '<span class="radecUnit">"</span></div>'
        }
      }
      const posCIRS = this.$stel.convertFrame(this.$stel.core.observer, 'ICRF', 'JNOW', obj.getInfo('radec'))
      const radecCIRS = this.$stel.c2s(posCIRS)
      const raCIRS = this.$stel.anp(radecCIRS[0])
      const decCIRS = this.$stel.anpm(radecCIRS[1])

      if (this.appVersion && this.appMobile) {
        ret.push({
          key: that.$t('Ra'),
          class: that.$t('ra'),
          value: formatRA(raCIRS)
        })
        ret.push({
          key: that.$t('Dec'),
          class: that.$t('dec'),
          value: formatDec(decCIRS)
        })
        ret.push({
          key: that.$t('Ra/Dec'),
          value: formatRA(raCIRS) + '&nbsp;&nbsp;/&nbsp;&nbsp;' + formatDec(decCIRS)
        })
      } else {
        ret.push({
          key: that.$t('Ra/Dec'),
          value: formatRA(raCIRS) + '&nbsp;&nbsp;&nbsp;' + formatDec(decCIRS)
        })
      }
      const azalt = this.$stel.c2s(this.$stel.convertFrame(this.$stel.core.observer, 'ICRF', 'OBSERVED', obj.getInfo('radec')))
      const az = this.$stel.anp(azalt[0])
      const alt = this.$stel.anpm(azalt[1])
      if (this.appVersion && this.appMobile) {
        ret.push({
          key: that.$t('Az'),
          class: that.$t('az'),
          value: formatAz(az)
        })
        ret.push({
          key: that.$t('Alt'),
          class: that.$t('alt'),
          value: formatDec(alt, false)
        })
        ret.push({
          key: that.$t('Az/Alt'),
          value: formatAz(az) + '&nbsp;&nbsp;/&nbsp;&nbsp;' + formatDec(alt, false)
        })
      } else {
        ret.push({
          key: that.$t('Az/Alt'),
          value: formatAz(az) + '&nbsp;&nbsp;&nbsp;' + formatDec(alt, false)
        })
      }

      this.$stel.core.constellations.custom_constellation = this.selectedObject.registry.custom_constellation === null ? 'NaN' : this.selectedObject.registry.custom_constellation
      return ret
    },

    getFormatted (field, defaultValue) {
      if (!('registry' in this.selectedObject)) {
        return defaultValue
      }
      const registry = this.selectedObject.registry

      if (field === 'lat') {
        return this.latToDec(registry[field])
      }

      if (field === 'lon') {
        return this.lngToRa(registry[field])
      }

      return defaultValue
    },

    latToDec: function (latitude) {
      const sign = latitude >= 0 ? '+' : '-'
      latitude = Math.abs(latitude)
      var DEC = Math.floor(latitude).toString() + '° '
      latitude = (latitude - Math.floor(latitude)) * 60
      DEC += Math.floor(latitude).toString() + '\' '
      latitude = (latitude - Math.floor(latitude)) * 60
      DEC += latitude.toFixed(1) + '"'

      const parts = DEC.split(/ /g)
      const degrees = parseInt(parts[0])

      return {
        sign: sign,
        degrees: Math.abs(degrees),
        arcminutes: parseInt(parts[1]),
        arcseconds: parseInt(parts[2].split('.')[0]),
        fraction: parseInt(parts[2].split('.')[1])
      }
    },

    lngToRa: function (longitude) {
      longitude = (-longitude + 180) / 15
      var RA = Math.floor(longitude).toString() + 'h '
      longitude = (longitude - Math.floor(longitude)) * 60
      RA += Math.floor(longitude).toString() + 'm '
      longitude = (longitude - Math.floor(longitude)) * 60
      RA += Math.floor(longitude).toFixed(1) + 's'

      const parts = RA.split(/ /g)

      return {
        hours: parseInt(parts[0]),
        minutes: parseInt(parts[1]),
        seconds: parseInt(parts[2].split('.')[0]),
        fraction: parseInt(parts[2].split('.')[1])
      }
    },

    formatPhase: function (v) {
      return (v * 100).toFixed(0) + '%'
    },
    formatMagnitude: function (v) {
      if (!v) {
        return 'Unknown'
      }
      return v.toFixed(2)
    },
    formatDistance: function (d) {
      // d is in AU
      if (!d) {
        return 'NAN'
      }
      const ly = d * swh.astroConstants.ERFA_AULT / swh.astroConstants.ERFA_DAYSEC / swh.astroConstants.ERFA_DJY
      if (ly >= 0.1) {
        return ly.toFixed(2) + ' light years'
        // return ly.toFixed(2) + '<span class="radecUnit"> light years</span>'
      }
      if (d >= 0.1) {
        return d.toFixed(2) + ' AU'
        // return d.toFixed(2) + '<span class="radecUnit"> AU</span>'
      }
      const meter = d * swh.astroConstants.ERFA_DAU
      if (meter >= 1000) {
        return (meter / 1000).toFixed(2) + ' km'
        // return (meter / 1000).toFixed(2) + '<span class="radecUnit"> km</span>'
      }
      return meter.toFixed(2) + ' m'
      // return meter.toFixed(2) + '<span class="radecUnit"> m</span>'
    },
    formatTime: function (jdm) {
      var d = new Date()
      d.setMJD(jdm)
      const utc = new Moment(d)
      utc.utcOffset(this.$store.state.stel.utcoffset)
      return utc.format('HH:mm')
    },
    unselect: function () {
      this.$stel.core.show_selection_window = false
    },
    lockToSelection: function () {
      if (this.$stel.core.selection) {
        this.$stel.pointAndLock(this.$stel.core.selection, 0.5)
        this.zoomPicker()
      }
    },
    zoomInButtonClicked: function () {
      const currentFov = this.$store.state.stel.fov * 180 / Math.PI
      // console.log(currentFov)
      if (currentFov > 10) {
        this.fov = true
        this.$stel.zoomTo(currentFov * 0.3 * Math.PI / 180, 0.4)
        const that = this
        this.zoomTimeout = setTimeout(_ => { that.zoomInButtonClicked() }, 300)
        if (this.$stel.pointAndLock(this.$stel.core.selection)) {
          this.zoomTimeout = setTimeout(_ => { that.zoomInButtonClicked() }, 300)
        }
      }
    },
    zoomPicker: function () {
      if (this.onResizeWidth() >= 600) {
        this.$stel.zoomTo(this.zoomValue * 0.3 * Math.PI / 180, 0.4)
      } else {
        this.$stel.zoomTo(this.zoomMobileValue * 0.3 * Math.PI / 180, 0.4)
      }
    },
    zoomOutButtonClicked: function () {
      const currentFov = this.$store.state.stel.fov * 180 / Math.PI
      this.$stel.zoomTo(currentFov * 3 * Math.PI / 180, 0.6)
      const that = this
      this.zoomTimeout = setTimeout(_ => { that.zoomOutButtonClicked() }, 200)
      if (this.$stel.pointAndLock(this.$stel.core.selection)) {
        this.zoomTimeout = setTimeout(_ => { that.zoomOutButtonClicked() }, 200)
      }
    },
    stopZoom: function () {
      if (this.zoomTimeout) {
        clearTimeout(this.zoomTimeout)
        this.zoomTimeout = undefined
      }
    },
    extraButtonClicked: function (btn) {
      btn.callback()
    },
    copyLink: function () {
      const input = document.querySelector('#link_inputid')
      input.focus()
      input.select()
      this.copied = document.execCommand('copy')
      window.getSelection().removeAllRanges()
      this.showShareLinkDialog = false
    },
    showMoreLess: function () {
      this.showContent = !this.showContent
    }
  },
  mounted: function () {
    const that = this
    that.items = that.computeItems()
    window.addEventListener('mouseup', function (event) {
      that.stopZoom()
    })
  }
}
</script>

<style lang="scss" scoped>
  .star-options{
    position: absolute;
    bottom: 300px !important;
    right: 20px;
    @media screen and (max-width: 499px){
      bottom: calc(25vh - 25px) !important;
    }
    z-index: 2;
  }
  .zoomDisabled{
    display: none;
  }
</style>
