<template>
  <div class="nav-container" v-if="!appMobile()">
    <img src="@/assets/images/ic_navigation.svg" @click="navOpen"></img>
  </div>
</template>

<script>

import swh from '@/assets/sw_helpers.js'

export default {
  data () {
    return {
      open: false
    }
  },
  methods: {
    navOpen: function () {
      this.open = !this.open
      this.$store.commit('setOpenMenu', this.open)
    },
    appMobile: function () {
      var app = swh.checkApp()
      if (app) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-container{
    margin-top: 5px;
    @media screen and (min-width: 600px){
      display: none;
    }
  }
</style>
