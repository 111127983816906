<template>
  <div class="tsearch">
    <skysource-search v-model="obsSkySource" floatingList="true"></skysource-search>
  </div>
</template>

<script>
import targetSearchMixin from '@/components/mixins/base-target-search.vue'
import SkysourceSearch from '@/components/skysource-search-mobile.vue'

export default {
  mixins: [targetSearchMixin],
  components: {
    SkysourceSearch
  }
}
</script>

<style>
@media all and (min-width: 600px) {
  .tsearch {
    z-index: 2;
  }
}
</style>
