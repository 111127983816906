<template>
  <div style="position: relative;" v-click-outside="resetSearch">
    <div class="search-input">
      <input
        :class="{'error-message': errorMsg, 'focused': focused}"
        class="input-star"
        v-model="searchText"
        :placeholder="showLabel"
        @focus="check()"
        @input="resetErrorMsg()"
        @click="resetErrorMsg(); openDropdown()"
        @keyup.esc="resetSearch()"
        @keyup.enter="refresh()"
        hide-details single-line
      />
      <span class="svg-star" :class="{'error-svg': errorMsg}" @click="refresh()" >
         <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
           <path fill-rule="evenodd" clip-rule="evenodd"
                 d="M2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7ZM7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C8.57591 14 10.0302 13.4792 11.2001 12.6004C11.2281 12.6376 11.259 12.6733 11.2929 12.7071L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L12.7071 11.2929C12.6733 11.259 12.6376 11.2281 12.6004 11.2001C13.4792 10.0302 14 8.57591 14 7C14 3.13401 10.866 0 7 0Z"/>
         </svg>
      </span>
    </div>
    <div
      class="suggested-input-search"
      :class="{'show': showDropdown}"
    >
      <span>Recent Searches:</span>
        <span
          class="reg-num"
          v-for="(text, index) in suggestSearchQuery"
          :key="index"
          @click="selectQuery(text)"
          style="cursor: pointer;"
        >
          {{text}}
          </span>
    </div>
  </div>
</template>

<script>
import swh from '@/assets/sw_helpers.js'
import vClickOutside from 'v-click-outside'
import _ from 'lodash'

export default {
  data: function () {
    return {
      autoCompleteChoices: [],
      searchText: '',
      lastQuery: undefined,
      errorMsg: false,
      mobileApp: false,
      focused: false,
      queryArr: [],
      suggestSearchQuery: [],
      showDropdown: false
    }
  },
  props: ['value', 'floatingList'],
  watch: {
    searchText: function () {
      if (this.searchText === '') {
        this.autoCompleteChoices = []
        this.lastQuery = undefined
      }
    },
    autoCompleteChoices () {
      if (this.autoCompleteChoices.length > 0) {
        this.sourceClicked(this.autoCompleteChoices[0])
      }
    },
    errorMsg: function (value) {
      if (value) {
        this.resetSearch()
      }
    },
    queryArr: function () {
      this.suggestSearchQuery = this.queryArr
    }
  },
  computed: {
    listStyle: function () {
      return this.floatingList ? 'position: absolute; z-index: 1000; margin-top: 8px' : ''
    },
    showList: function () {
      return this.searchText !== ''
    },
    showLabel: function () {
      return this.errorMsg ? 'Enter valid registration number' : this.$t('Enter your registration number ...')
    },
    appVersion: function () {
      var version = swh.checkAppVersion()
      if (version) {
        return true
      } else {
        return false
      }
    },
    appMobile: function () {
      var app = swh.checkApp()
      if (app) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    sourceClicked: function (val) {
      this.$emit('input', val)
    },
    resetSearch: function () {
      this.showDropdown = false
      this.searchText = ''
    },
    refresh: _.debounce(function () {
      if (this.appMobile) {
        this.searchApp()
      } else {
        var that = this
        let str = that.searchText
        str = str.toUpperCase()
        str = str.replace(/\s+/g, '')
        // if (this.lastQuery === str) {
        //   return
        // }
        this.lastQuery = str
        swh.querySkySources(str).then(results => {
          this.errorMsg = false
          this.showDropdown = false
          if (!results) {
            this.errorMsg = true
            this.focused = false
          } else {
            if (!this.queryArr.includes(that.searchText)) {
              this.queryArr.push(that.searchText)
              localStorage.setItem('searchQuery', JSON.stringify(this.queryArr))
            }
          }
          if (str !== that.lastQuery) {
            console.log('Cancelled query: ' + str)
            return
          }
          that.autoCompleteChoices = results
        }, () => { that.resetSearch() })
      }
    }, 200),
    nameForSkySource: function (s) {
      const cn = swh.cleanupOneSkySourceName(s.match)
      const n = swh.nameForSkySource(s)
      if (cn === n) {
        return n
      } else {
        return cn + ' (' + n + ')'
      }
    },
    searchApp: function () {
      if (this.appVersion) {
        window.MessageInvoker.postMessage(JSON.stringify({ type: 'search' }))
      } else {
        window.MessageInvoker.postMessage('search')
      }
    },
    typeToName: function (t) {
      return swh.nameForSkySourceType(t)
    },
    iconForSkySource: function (s) {
      return swh.iconForSkySource(s)
    },
    resetErrorMsg: function () {
      this.errorMsg = false
    },
    check: function () {
      this.focused = true
      if (!this.focused) {
        this.focused = false
      }
    },
    selectQuery: function (text) {
      this.searchText = text
      this.refresh()
      this.showDropdown = false
    },
    openDropdown: function () {
      if (this.appMobile) {
        this.searchApp()
      } else {
        if (this.suggestSearchQuery.length !== 0) {
          this.showDropdown = true
        } else {
          this.showDropdown = false
        }
      }
    }
  },
  mounted: function () {
    var that = this
    // const onClick = e => {
    //   if (that.searchText !== '') {
    //     that.searchText = ''
    //   }
    //   console.log(that.searchText)
    // }
    that.suggestSearchQuery = JSON.parse(localStorage.getItem('searchQuery')) || []
    if (that.queryArr.length === 0) {
      that.queryArr = that.suggestSearchQuery
    }
    // const guiParent = document.querySelector('stel') || document.body
    // guiParent.addEventListener('click', onClick, false)
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style>
.v-text-field input {
  min-width: 280px !important;
}
.theme--dark.v-text-field > .v-input__control > .v-input__slot:before{
  border: none;
}
.v-application .primary--text, .mdi-magnify::before{
  color: #FFA751 !important;
}
</style>

<style lang="scss" scoped>
.search-input{
  display: flex;
  align-items: flex-end;
  border-left: 1px solid rgba(255,255,255,0.1);
  border-right: 1px solid rgba(255,255,255,0.1);
  .input-star{
    width: 100%;
    min-width: 435px;
    height: 25px;
    color: #ffffff;
    outline: none;
    text-align: left;
    margin-left: 50px;
    font-family: Proxima_Regular;
    font-size: 16px;
    @media screen and (max-width: 959px){
      min-width: 230px;
    }
    &::placeholder{
      color: #ffffff;
      opacity: 0.7;
    }
    &.error-message{
      &::placeholder{
        color: #FF4040;
      }
    }
    &.focused{
      transition: all 0.6s;
      border-bottom: 1px solid rgb(255, 167, 81)
    }
  }
  .svg-star{
    margin-right: 50px;
    @media screen and (max-width: 959px){
      margin: 0 25px;
    }
    svg {
      fill: #ffa751;
    }
    &.error-svg{
      svg{
        fill: #FF4040;
      }
    }
  }
}
.suggested-input-search{
  display: none;
  position: absolute;
  margin-top: 25px;
  width: 100%;
  background: rgba(16, 20, 32, 0.9);
  backdrop-filter: blur(10px);
  &.show{
    display: block;
  }
  span {
    display: block;
    font-weight: 600;
    color: #FFFFFF;
    padding: 10px;
    font-family: Proxima_Bold;
    &.reg-num {
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
</style>
