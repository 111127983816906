<template>
  <div class="selected-object-pointer" v-if="appMobile && selectedObject" v-bind:class="{ 'arrow-visible': distance > 0.05 }" v-bind:style="{ transform: rotateStyle }">
    <svg version="1.1" id="icons_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128" xml:space="preserve">
      <g id="row1_1_">
        <g id="_x31__3_">
          <path class="st1" d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6z" id="_x32__2_"/>
          <path class="st2" d="M49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z" id="_x32__2_"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<style lang="scss">
.selected-object-pointer {
  width: 100px;
  height: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;

  svg {
    path {
      fill: #fff;
      &.st2 {
        display: none;
      }
    }
  }

  &.arrow-visible {
    svg {
      path {
        &.st2 {
          display: inline;
        }
      }
    }
  }
}
</style>

<script>
import swh from '@/assets/sw_helpers.js'

export default {
  data () {
    return {
      watchInterval: null,
      yaw: 0,
      pitch: 0,
      roll: 0,
      rotation: 0,
      distance: 0
    }
  },
  created () {
    // Needs to be set via interval because there's no other way to access this
    var that = this
    this.watchInterval = setInterval(function () {
      that.yaw = that.$stel.anpm(that.$stel.core.observer.yaw)
      that.pitch = that.$stel.core.observer.pitch
      that.roll = that.$stel.core.observer.roll
    }, 10)
  },
  destroyed () {
    clearInterval(this.watchInterval)
  },
  computed: {
    appMobile: function () {
      return swh.checkApp()
    },
    appVersion: function () {
      return swh.checkAppVersion()
    },
    selectedObject: function () {
      return this.$store.state.selectedObject
    },
    rotateStyle () {
      return 'rotate(' + this.rotation + 'deg)'
    }
  },
  watch: {
    selectedObject () {
      this.updatePointer()
    },
    yaw () {
      this.updatePointer()
    },
    pitch () {
      this.updatePointer()
    },
    roll () {
      this.updatePointer()
    }
  },
  methods: {
    updatePointer () {
      if (!this.selectedObject) {
        this.distance = 0
        return
      }

      const selectionVector = this.$stel.getTargetPos()
      const selectionAngles = this.$stel.c2s(selectionVector)

      let [selectionYaw, selectionPitch] = selectionAngles
      selectionYaw = this.$stel.anpm(selectionYaw)

      this.rotation = this.$stel.anpm(Math.atan2(-(selectionPitch - this.pitch), this.$stel.anpm(selectionYaw - this.yaw)) - this.roll) * (180 / Math.PI)
      this.distance = Math.sqrt(Math.pow((selectionYaw - this.yaw), 2) + Math.pow((selectionPitch - this.pitch), 2)) / this.$store.state.stel.fov
    }
  }
}
</script>
