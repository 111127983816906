<template>
  <div class="sidebar" v-if="$store.state.openMenu">
    <div class="sidebar-logo">
      <img class="" id="stellarium-web-toolbar-logo" src="@/assets/images/sr_logo.svg" width="20" height="20" alt="Star Registration Side Bar Logo"/>
      <span style="font-family: Proxima_Light" class=""><strong style="font-family: Proxima_Bold">STAR</strong>REGISTRATION</span>
    </div>
    <img class="close-button" @click="$store.commit('setOpenMenu', false)" src="@/assets/images/ic_close.svg" width="10" height="10" alt="Close"></img>
    <top-tooltip></top-tooltip>
  </div>
</template>

<script>
import TopTooltip from '@/components/top-tooltip.vue'

export default {

  components: { TopTooltip }
}
</script>

<style lang="scss" scoped>
  .sidebar{
    transition: max-height 0.7s;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #101420;
    .sidebar-logo{
      height: 20px;
      display: flex;
      margin-top: 20px;
      font-size: 18px;
      justify-content: center;
      align-items: center;
      font-weight: 300;
      span{
        strong{
          font-weight: bold;
        }
      }
    }
    .close-button{
      position: absolute;
      right: 5%;
      top: 3%;
    }
  }
  .about-us{
    display: flex;
    font-family: Proxima_Bold;
    justify-content: center;
    margin: 10px 0 0 0;
    text-align: center;
  }
  #stellarium-web-toolbar-logo{
    margin-left: 10px;
  }
</style>
