
<template>

  <div v-on:click="isActive(!isOn, true); zoomPicker()" ref="gyroscope" class="gyroscope-container get-click" :class="{on: toggled}" v-if="(appMobile || (appMobile && appVersion))">
    <a :class="{gyroscopeOn: isOn}">
      <svg width="37.5px" height="37.5px" version="1.1" viewBox="70 50 550 450" xmlns="http://www.w3.org/2000/svg" style="margin: 5px;min-height: 38px" xmlns:xlink="http://www.w3.org/1999/xlink">
        <circle cx="25" cy="25" r="25" stroke="black" stroke-witdh="3" fill="#1E1E1E"/>
          <path d="m275.23 490.73c-85.812-28.895-145-108.48-147.28-199.91-0.078124-3.0469-2.5078-5.4961-5.5586-5.4961h-15.418c-3.1367 0-5.7227 2.5898-5.6484 5.7266 2.4297 104.81 71.441 195.88 170.94 226.75 1.3047 0.40625 2.1289 1.6836 1.9297 3.0352l-2.8086 19.242c-0.3125 2.1328 1.8047 3.8047 3.8047 3.0078l64.98-25.852c2.0039-0.79688 2.3906-3.4609 0.69922-4.7969l-54.875-43.355c-1.6914-1.3359-4.1953-0.33984-4.5078 1.793l-2.5664 17.578c-0.25 1.7266-2.0312 2.8359-3.6914 2.2773z" fill="#ffffff"/>
          <path d="m425.81 39.168 2.8086-19.242c0.3125-2.1328-1.8047-3.8047-3.8047-3.0078l-64.984 25.855c-2.0039 0.79687-2.3906 3.4609-0.69922 4.7969l54.875 43.355c1.6914 1.3359 4.1953 0.33984 4.5078-1.793l2.5664-17.578c0.25391-1.7344 2.0352-2.8398 3.6953-2.2812 85.812 28.895 145 108.48 147.28 199.91 0.078124 3.0469 2.5078 5.4961 5.5586 5.4961h15.418c3.1367 0 5.7227-2.5898 5.6484-5.7266-2.4297-104.8-71.441-195.87-170.94-226.75-1.3086-0.40234-2.1289-1.6836-1.9297-3.0352z" fill="#ffffff"/>
          <path d="m244.09 54.148-119.95 119.95c-16.527 16.527-16.527 43.414 0 59.938l271.82 271.82c16.523 16.523 43.41 16.523 59.938 0l119.95-119.95c16.527-16.527 16.527-43.414 0.003907-59.938l-271.82-271.83c-16.523-16.527-43.414-16.527-59.941 0.003907zm-58.984 86.246c-1.5312-1.5312-1.5312-4.0234 0-5.5508l19.738-19.738c1.5312-1.5312 4.0234-1.5312 5.5508 0l0.46094 0.46094c1.5312 1.5312 1.5312 4.0195 0 5.5508l-19.738 19.738c-1.5312 1.5312-4.0234 1.5312-5.5508 0zm338.28 274.68-38.309 38.309c-3.0977 3.0977-8.1641 3.0977-11.258 0-3.0977-3.0977-3.0977-8.1641 0-11.258l38.309-38.309c3.0977-3.0977 8.1641-3.0977 11.258 0 3.0977 3.0938 3.0977 8.1641 0 11.258zm37.625-91.188c2.0781 2.0781 2.0781 5.4492 0 7.5273l-159.59 159.59c-2.0781 2.0781-5.4492 2.0781-7.5273 0l-263.25-263.25c-2.0781-2.0781-2.0781-5.4492 0-7.5273l159.59-159.6c2.0781-2.0781 5.4492-2.0781 7.5273 0z" fill="#ffffff"/>
      </svg>
      <span style="font-size:10px;color:white;"><b>AR</b></span>
    </a>
  </div>

</template>

<script>
import swh from '@/assets/sw_helpers.js'
// import Moment from 'moment'
export default {
  data: function () {
    return {
      isOn: false,
      zoomMobileValue: 40
    }
  },
  state: {
    gyroStatus: [
      {
        id: 1,
        isOn: true
      }
    ]
  },
  getters: {
    gyroStatus (state) {
      return state.gyroStatus
    }
  },
  mutations: {
    updateGyroStatus (state, item) {
      state[item.key][item.payload].isLoaded = item.value
    }
  },
  name: 'gyroscope-button',
  props: ['label', 'toggled', 'd', 'fillRule', 'clipRule', 'fill'],
  computed: {
    appMobile: function () {
      var app = swh.checkApp()
      if (app) {
        return true
      } else {
        return false
      }
    },
    appVersion: function () {
      var version = swh.checkAppVersion()
      if (version) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    clicked: function () {
      var b = !this.toggled
      this.$emit('clicked', b)
    },
    isActive: function (gyro, sendEvent = false) {
      this.isOn = gyro
      var elLocation = document.querySelectorAll('.location')
      var elTime = document.querySelectorAll('.time')

      if (this.isOn) {
        elLocation[0].classList.add('gyroscopeEnabled')
        elTime[0].classList.add('gyroscopeEnabled')
        if (sendEvent) {
          window.MessageInvoker.postMessage(JSON.stringify({ type: 'gyroscope', enabled: true }))
        }
      } else {
        elLocation[0].classList.remove('gyroscopeEnabled')
        elTime[0].classList.remove('gyroscopeEnabled')
        if (sendEvent) {
          window.MessageInvoker.postMessage(JSON.stringify({ type: 'gyroscope', enabled: false }))
        }
      }
      // this.$store.commit('updateGyroStatus', { key: 'gyroStatus', index: 1, value: gyro })
      // console.log(this.$store.commit('updateGyroStatus', { key: 'gyroStatus', index: 1, value: gyro }))
    },
    zoomPicker: function () {
      // if (!window.gyroAppEnable()) {
      //   return false
      // }
      this.$stel.zoomTo(this.zoomMobileValue * 0.3 * Math.PI / 180, 0.4)
    }
  },
  mounted () {
    window.gyroAppEnable = (gr, sendEvent) => {
      // if (gr) {
      this.isActive(gr, sendEvent)
      this.$stel.zoomTo(150 * 0.3 * Math.PI / 180, 0.4)
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
  .gyroscope-container{
    position:relative;
    //bottom: 20%;
    //right: 10%;
    a{
      //background: rgba(16,20,32,0.7);
      //backdrop-filter: blur(10px);
      //border-radius: 3px;
      height: 48px;
      display: block;
      img{
        margin: 5px;
      }
    }
    .hint{
      display: none;
      position: absolute;
      bottom: 85%;
      color: white;
      width: 120px;
      font-size: 12px;
      text-align: center;
      left: -80%;
      line-height: 35px;
      background: rgba(16, 20, 32, 0.7);
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      margin-bottom: 5px;
      border-radius: 4px;
    }
    &:hover{
      .hint{
        display:block;
        font-family: Proxima_Bold;
      }
    }
    .gyroscopeOn{
      path, b{
        fill: #FFA751;
        color: #FFA751;
      }
    }
  }
  .mobileCss{
    .gyroscope-container{
      width: 45px;
      a{
        display: flex;
        align-items: flex-end;
        height: 60px;
        width: 80px;
        margin-top: 3px;
        svg{
          height: 50px;
          width: 50px;
        }
      }
    }
  }
  @media (orientation: landscape) and (max-width:499px){
    .mobileCss{
      .gyroscope-container{
        a{
          margin-top: 0;
          svg{
            margin: 2px 5px !important;
          }
        }
      }
    }
  }
</style>
