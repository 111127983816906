<script>
import swh from '@/assets/sw_helpers.js'

export default {
  data: function () {
    return {
      obsSkySource: undefined
    }
  },
  watch: {
    obsSkySource: function (ss) {
      if (!ss) {
        return
      }

      swh.addStarObject(ss, this.$store)
    }
  }
}
</script>
