<template>
  <div class="header-logo-mobile">
    <img class="tbtitle" id="stellarium-web-toolbar-logo" src="@/assets/images/sr_logo.svg" width="20" height="20" alt="Star Registration Mobile Logo"/>
    <span style="font-family: Proxima_Light" class="tbtitle"><strong style="font-family: Proxima_Bold">STAR</strong>REGISTRATION</span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.header-logo-mobile{
  display: flex;
  align-items: center;
  @media screen and (min-width: 600px){
    display: none;
  }
  span{
    font-size: 21px;
    font-weight: 300;
    strong{
      font-weight: bold;
    }
  }
  #stellarium-web-toolbar-logo{
    margin-left: 0;
  }
}
</style>
