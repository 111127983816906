
<template>
  <div class="about-us" :class="{on: toggled}" v-if="!appMobile()">
    <div class="about-us-label">
      <img src="@/assets/images/svg/ui/ic_info.svg" height="18px" style="min-height: 18px"/>
      <span class="label">{{ aboutUsLabel }}</span>
    </div>
      <span class="tooltipText">
        {{ onHoverText }}
        <a href="https://apps.apple.com/app/id1627298548" target="_blank" style="display:block; margin-top: 10px;">
          <img src="@/assets/images/app_store_white_eng.svg" alt="Apple App Badge Logo" style="margin: 0"/>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.starregistration.star_finder" target="_blank" style="display:block; margin-top: 5px;">
          <img src="@/assets/images/google_play_white.png" alt="Google Play Badge Logo" style="margin: 0"/>
        </a>
      </span>
  </div>
</template>

<style lang="scss" scoped>
.about-us {
  display: flex;
  margin-right: 30px;
  align-items: center;
  position: relative;
  @media screen and (max-width: 959px){
    margin-right: 10px;
  }
  @media screen and (max-width: 599px){
    display: flex;
    flex-direction: column;
  }
  img {
    margin-right: 10px;
  }
  .about-us-label{
    display:flex;
    align-items: center;
  }
  .label {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 3px;
    font-family: Proxima_Bold;
  }
  .tooltipText{
    visibility: hidden;
    width: 300px;
    min-height: 75px;
    background: rgba(16, 20, 32, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 50px 15px;
    top: 100%;
    right: 0;
    @media screen and (max-width: 599px){
      visibility: visible;
      font-family: Proxima_Regular;
      font-size: 16px;
      padding: 15px;
      position: unset;
      margin-top: 0;
    }
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  &:hover .tooltipText {
    visibility: visible;
    font-family: Proxima_Regular;
    font-size: 16px;
  }
  @media screen and (max-width: 599px){
    display: none;
  }
}
</style>

<script>

import swh from '@/assets/sw_helpers.js'

export default {
  name: 'top-tooltip',
  props: ['label', 'img', 'toggled', 'img_alt'],
  methods: {
    clicked: function () {
      var b = !this.toggled
      this.$emit('clicked', b)
    },
    appMobile: function () {
      var app = swh.checkApp()
      if (app) {
        return true
      }
      return false
    }
  },
  computed: {
    aboutUsLabel: function () {
      return this.$t('About')
    },
    onHoverText: function () {
      return this.$t('This star finder is the adoption of sky object web search by Stellarium')
    }
  }
}
</script>
